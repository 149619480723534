import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";

const TechnicalProblem = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
    >
      <Card sx={{ maxWidth: 400, boxShadow: 3 }}>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Technical Issue
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            We are currently experiencing a technical issue. Please use our
            website to log in and raise a new ticket.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            href="https://littleboxindia.com/account/login"
            sx={{ fontWeight: "bold" }}
          >
            Go to Website
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TechnicalProblem;
