const comboSKUs = [
  "TP1549",
  "TP1540",
  "TP1548",
  "TP1547",
  "TP1550",
  "TP1543",
  "TP1542",
  "TP1546",
  "TP1545",
  "TP1544",
  "TP1541",
  "TR1332",
  "TR1331",
  "TR1330",
  "TR1328",
  "TR1327",
  "TR1326",
  "TR1325",
  "TP1572",
  "TP1571",
  "TP1570",
  "TP1569",
  "TP1568",
  "TP1567",
  "TP1566",
  "TP1565",
  "TP1564",
  "TP1563",
  "TP1562",
  "TP1561",
  "TP1560",
  "TR1296",
  "TR1295",
  "TR1295",
  "TR1290",
  "TR1289",
  "TR1288",
  "TR1287",
  "TR1272",
  "TR1265",
  "ST1263",
  "TP1505",
  "TR1216",
  "TR1215",
  "TR1212",
  "TR1209",
  "TR1198",
  "TR1197",
  "TR1204",
  "TR1203",
];

export const isProductCombo = (product) => {
  //check name for combo or set
  const name = String(product.name).toLowerCase();
  const sku = product.sku;
  if (name.includes("combo") || name.includes("set")) {
    return true;
  } else if (comboSKUs.includes(sku)) {
    return true;
  } else {
    return false;
  }
};

// export const isWebView = () => {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   console.log(userAgent);

//   // Check for WebView indicators
//   const webViewIndicators = [
//     "wv", // Android WebView
//     "WebView", // iOS WebView
//     "SamsungBrowser", // Some Samsung WebViews
//   ];

//   // Check user agent for specific keywords
//   for (let indicator of webViewIndicators) {
//     if (userAgent.includes(indicator)) {
//       return true;
//     }
//   }

//   // Additional check for Android
//   if (/Android/i.test(userAgent) && !/Chrome|Firefox|Safari/i.test(userAgent)) {
//     return true;
//   }

//   // Additional check for iOS
//   if (/iPhone|iPad|iPod/i.test(userAgent) && !/Safari/i.test(userAgent)) {
//     return true;
//   }

//   return false;
// };

export const isWebView = () => {
  return window.ReactNativeWebView ? true : false;
};

export const changeTimezoneToAsiaKolkata = (datestring) => {
  // Parse the date string into a Date object
  const date = new Date(datestring);
  // Get the UTC time in milliseconds
  const utcTime = date.getTime() - date.getTimezoneOffset() * 60000;
  // India Standard Time (IST) is UTC + 5:30 hours
  const timeZoneOffset = 5.5 * 60 * 60000 * 0; // 5 hours 30 minutes in milliseconds
  // Adjust the time for the Asia/Kolkata timezone (UTC +5:30)
  const kolkataTime = new Date(utcTime + timeZoneOffset);
  return kolkataTime.toLocaleString("en-us");
};
