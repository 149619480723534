import React from "react";
import {
  Card,
  CardContent,
  Chip,
  Typography,
  Box,
  Divider,
} from "@mui/material";

const TicketListForMobile = ({ tickets, Ticketsempty, handleClick }) => {
  // If there are no tickets, show a message
  if (Ticketsempty) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 3,
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No ticket found
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: 3, padding: 2 }}>
      {/* Render the list of tickets */}
      {Object.keys(tickets).map((ticketId) => (
        <Card
          key={ticketId}
          sx={{
            marginBottom: 3,
            boxShadow: 3,
            borderRadius: "12px",
            cursor: "pointer",
            ":hover": {
              bgcolor: "rgba(0, 0, 255, 0.1)",
            },
            transition: "0.3s",
          }}
          onClick={() => {
            handleClick(ticketId);
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              Ticket ID: {ticketId}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 0.5 }}
            >
              Created: {new Date(tickets[ticketId].created_at).toLocaleString()}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 0.5 }}
            >
              Category: {tickets[ticketId].category}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 1 }}
            >
              Question: {tickets[ticketId].question}
            </Typography>

            <Divider sx={{ marginBottom: 1 }} />

            <Chip
              label={tickets[ticketId].status}
              color={
                tickets[ticketId].status === "new"
                  ? "info"
                  : tickets[ticketId].status === "open"
                  ? "success"
                  : "error"
              }
              sx={{
                borderRadius: "12px",
                padding: "4px 12px",
                fontSize: "0.875rem",
              }}
            />
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default TicketListForMobile;
