import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getExchangeHistory } from "../actions/shopifyLambda";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  Chip,
  Alert,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

String.prototype.orderno = function () {
  return "#" + this.split("_")?.at(-1)?.split(".")?.at(0);
};
String.prototype.fulfillmentno = function () {
  const fid = this.split("_")?.at(-1);
  const orderno = fid.split(".")?.at(0);
  const fno = fid.split(".")?.at(1);
  return `#${orderno}-F${fno}`;
};

const ExchangeHistory = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [exchanges, setExchanges] = useState([]);
  const customerId = searchParams.get("customer");

  const fetchExchangeHistory = async (customerId) => {
    try {
      setLoading(true);
      const response = await getExchangeHistory(customerId);
      if (response.statusCode === 200) {
        const exchanges = response?.body?.exchange_history;
        setExchanges(exchanges);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchExchangeHistory(customerId);
    }
  }, [customerId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (Object.keys(exchanges).length === 0) {
    return (
      <Box sx={{ padding: 2, height: "50vh" }}>
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => fetchExchangeHistory(customerId)}
            >
              Retry
            </Button>
          }
        >
          You may not have initiated any exchanges. Click retry to fetch the
          data again.
        </Alert>
      </Box>
    );
  }

  return (
    <div style={{ padding: "16px", minHeight: "100vh" }}>
      <Typography variant="h5" gutterBottom>
        Exchange History
      </Typography>
      <Grid container spacing={4}>
        {Object.entries(exchanges)
          .sort((a, b) => {
            const aFid = Number(a[0].orderno().split("#")?.at(-1));
            const bFid = Number(b[0].orderno().split("#")?.at(-1));
            return bFid - aFid;
          })
          .map(([fulfillmentId, exchange]) => (
            <Grid item xs={12} sm={6} md={4} key={fulfillmentId}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Original order No: <b>{fulfillmentId.orderno()}</b>
                    {/* #899669-F1 */}
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      ({fulfillmentId.fulfillmentno()})
                    </span>
                  </Typography>

                  {/* Status with Chip */}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginBottom: 2 }}
                    component="div"
                  >
                    <Chip
                      label={
                        exchange.new_order_no
                          ? `Success - Exchange order No: ${exchange.new_order_no}`
                          : "Pending"
                      }
                      color={exchange.new_order_no ? "success" : "warning"}
                      icon={
                        exchange.new_order_no ? (
                          <CheckCircleIcon />
                        ) : (
                          <HourglassEmptyIcon />
                        )
                      }
                      sx={{ marginBottom: 2 }}
                    />
                  </Typography>

                  {exchange.line_items.map((item, index) => (
                    <div key={index} style={{ marginBottom: "16px" }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <CardMedia
                            component="img"
                            image={
                              item.product_varient.product.media.nodes[0]
                                .preview.image.url
                            }
                            alt={item.product_varient.product.title}
                            sx={{ width: 60, height: 60, objectFit: "cover" }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" component="div">
                            <strong>
                              {item.product_varient.product.title}
                            </strong>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            SKU: {item.sku} | Size: {item.size}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default ExchangeHistory;
