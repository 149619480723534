import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Box,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Fab,
  Badge,
  Modal,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";

import {
  closeTicket,
  getSingleTicketOfCustomer,
  reopenTicket,
  sendMessage,
  sendTicketUpdateToShopify,
} from "../actions/ticketsLambda";
import Loader from "../components/Loader";
import styled from "@emotion/styled";
import { uploadToDrive } from "../actions/googledrive";
import ImageViewer from "../components/ImageViewer";
import { changeTimezoneToAsiaKolkata } from "../utils/helperFunctions";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: 10,
    bottom: 25,
    border: `2px solid white`,
    padding: "0 4px",
  },
}));
const ChatModule = () => {
  const navigate = useNavigate();
  const innerWidth = window.innerWidth;
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [pageErrorMessage, setPageErrorMessage] = useState(
    "Something Went Wrong, Please Try Again"
  );
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageId, setImageId] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [retryLoading, setRetryLoading] = useState(false);
  const [openRetryBox, setOpenRetryBox] = useState(false);
  const [openCloseRetryBox, setOpenCloseRetryBox] = useState(false);
  const [customerId, setcustomerId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageClose = () => {
    setOpenImage(false);
  };
  const handleRetryClose = () => {
    setOpenRetryBox(false);
  };
  const handleCloseRetryClose = () => {
    setOpenCloseRetryBox(false);
  };

  const handleChange = (fileArray) => {
    const selectedFilesLength = files.length;
    fileArray.forEach((fileItem, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = function () {
        const rawLog = reader.result.split(",")[1];
        const dataSend = {
          id: index + selectedFilesLength,
          size: fileItem.size,
          dataReq: {
            data: rawLog,
            name: fileItem.name,
            type: fileItem.type,
          },
          fname: "uploadFilesToGoogleDrive",
        };
        setFiles((f) => [...f, dataSend]);
      };
    });
  };

  const handleDeleteFiles = (id) => {
    const newFileArray = files
      .filter((f) => f.id !== id)
      .map((file, index) => ({ ...file, id: index }));
    setFiles(newFileArray);
  };
  const handleSend = async () => {
    if (!message) return;
    console.log("here");
    setOpen(false);
    setLoading(true);
    let payloadFiles = [];
    if (files.length > 0) {
      const uploadResponse = await uploadToDrive(files);
      if (uploadResponse.status === 200) {
        payloadFiles.push(...uploadResponse.data);
      } else {
        setLoading(false);
        setOpenRetryBox(true);
        return;
      }
    }
    const data = await sendMessage(
      ticket.fulfillment_id,
      message,
      payloadFiles
    );
    if (data.status === 200) {
      const fetched = data.data;
      const timeFormatted = {
        ...fetched,
        created_at: new Date(fetched.created_at).toLocaleString(),
        updated_at: new Date(fetched.updated_at).toLocaleString(),
        messages: fetched.messages
          .sort((a, b) => new Date(a.time) - new Date(b.time))
          .map((m) => ({
            ...m,
            // time: new Date(m.time).toLocaleString(),
            time: changeTimezoneToAsiaKolkata(m.time),
          })),
      };

      setTicket(timeFormatted);
      setMessage("");
    } else {
      setOpenRetryBox(true);
    }
    setLoading(false);
  };

  const handleCloseCase = async () => {
    setLoading(true);
    const data = await closeTicket(ticket);
    if (data.status === 200) {
      setTicket((prev) => ({
        ...prev,
        package_status: "closed",
        closed_by: "customer",
      }));
    } else {
    }
    setLoading(false);
  };

  const handleReopenCase = async () => {
    setLoading(true);
    const data = await reopenTicket(ticket);
    if (data.status === 200) {
      setTicket((prev) => ({
        ...prev,
        package_status: "reopen",
      }));
    } else {
    }
    setLoading(false);
  };

  const handleCloseRetry = async () => {
    setRetryLoading(true);
    const data = await closeTicket(ticket);
    if (data.status === 200) {
      setTicket((prev) => ({ ...prev, package_status: "closed" }));
      setOpenCloseRetryBox(false);
    }
    setRetryLoading(false);
  };

  const handleRetry = async () => {
    if (!message) return;
    setRetryLoading(true);
    let payloadFiles = [];
    if (files.length > 0) {
      const uploadResponse = await uploadToDrive(files);
      if (uploadResponse.status === 200) {
        payloadFiles.push(...uploadResponse.data);
      } else {
        setRetryLoading(false);
        return;
      }
    }
    const data = await sendMessage(
      ticket.fulfillment_id,
      message,
      payloadFiles
    );
    if (data.status === 200) {
      const fetched = data.data;
      const timeFormatted = {
        ...fetched,
        created_at: new Date(fetched.created_at).toLocaleString(),
        updated_at: new Date(fetched.updated_at).toLocaleString(),
        messages: fetched.messages
          .sort((a, b) => new Date(a.time) - new Date(b.time))
          .map((m) => ({
            ...m,
            // time: new Date(m.time).toLocaleString(),
            time: changeTimezoneToAsiaKolkata(m.time),
          })),
      };
      setTicket(timeFormatted);
      setOpenRetryBox(false);
      setMessage("");
    } else {
      console.log("error");
    }
    setRetryLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const allParams = new URL(window.location).searchParams;
      const ticketId = allParams.get("id");
      const customerId = allParams.get("customer");
      setcustomerId(customerId);
      const data = await getSingleTicketOfCustomer(ticketId);
      if (data.status === 200) {
        const fetched = data.data;
        await sendTicketUpdateToShopify(fetched.customer_id);
        if (Object.keys(fetched).length > 0) {
          const timeFormatted = {
            ...fetched,
            created_at: new Date(fetched.created_at).toLocaleString(),
            updated_at: new Date(fetched.updated_at).toLocaleString(),
            messages: fetched.messages.map((m) => ({
              ...m,
              // time: new Date(m.time).toLocaleString("en-us"),
              time: changeTimezoneToAsiaKolkata(m.time),
            })),
          };
          setTicket(timeFormatted);
        } else {
          setPageError(true);
          setPageErrorMessage("Not Found, Invalid Ticket");
        }
      } else {
        setPageError(true);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading && (
        <div className="load-overlay">
          <Loader />
        </div>
      )}
      {pageError ? (
        <Container>
          <h1>{pageErrorMessage}</h1>
        </Container>
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              zIndex: 100,
              width: "100%",
              backgroundColor: "#ffffff",
              padding: "10px 0",
            }}
          >
            {ticket.package_status !== "closed"
              ? innerWidth <= 730 && (
                  <Stack direction="row" gap={2}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleCloseCase}
                    >
                      Close Case
                    </Button>
                    <Button
                      sx={{ marginRight: 0, marginLeft: "auto" }}
                      variant="contained"
                      onClick={handleClickOpen}
                      endIcon={<SendIcon />}
                    >
                      Reply
                    </Button>
                  </Stack>
                )
              : innerWidth <= 730 && (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <h1>
                      This Ticket Is Closed by{" "}
                      {ticket.closed_by === "customer"
                        ? "You"
                        : "customer care"}
                    </h1>
                    {(ticket.closed_by === "customercare" ||
                      ticket.closed_by === "Little Box") && (
                      <div>
                        <h2 style={{ fontWeight: 400 }}>
                          If you are unsatisfied by our reply you can reopen
                          this case
                        </h2>
                        <span>
                          <Button
                            size="large"
                            variant="outlined"
                            onClick={handleReopenCase}
                          >
                            <span style={{ fontWeight: 800 }}>Reopen</span>
                          </Button>
                        </span>
                      </div>
                    )}
                  </Box>
                )}
          </div>
          <Stack>
            <div
              className="ticket-issue-container"
              style={{
                marginBottom: "57px",
              }}
            >
              <Container>
                <Stack direction="column" spacing={1}>
                  <Stack
                    className="ticketHistory"
                    direction="column"
                    gap="35px"
                    p={4}
                    sx={{
                      maxWidth: "100%",
                      height: "100%",
                      paddingTop: "30px",
                      borderRadius: "5px",
                      backgroundColor: "#f7f7f7",
                      border: "1px groove grey",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 2, // Added padding for spacing
                        backgroundColor: "#f5f5f5", // Optional: Set a light background for better visibility
                      }}
                    >
                      <h1 style={{ margin: 0 }}>LBIndia HelpDesk</h1>

                      {/* Go Back Button with Icon */}
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBack />}
                        sx={{
                          padding: "2px 10px", // Padding to make the button more spacious
                          borderRadius: "8px", // Rounded corners for the button
                          fontSize: "1rem", // Larger font size for readability
                          textTransform: "none", // Preserve the case of text
                          boxShadow: 2, // Light shadow for depth
                          "&:hover": {
                            backgroundColor: "#1976d2", // Slightly different color on hover
                          },
                        }}
                        onClick={() => {
                          navigate(`/mytickets?customer=${customerId}`);
                        }}
                      >
                        Go Back
                      </Button>
                    </Box>
                    <div className="ticket_details">
                      <h2>
                        Ticket ID :{" "}
                        <span style={{ color: "red" }}>
                          #{ticket.original_order_id}{" "}
                        </span>
                      </h2>
                    </div>
                    <Stack
                      className="time_stamp"
                      direction="row"
                      gap={4}
                      sx={{
                        "& h3": {
                          margin: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <h3>
                        Case Status :<span> {ticket.package_status}</span>
                      </h3>
                    </Stack>
                    <Stack
                      className="time_stamp"
                      direction="row"
                      gap={4}
                      sx={{
                        "& h3": {
                          margin: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <h3>Created At : {ticket.created_at}</h3>
                      <h3>Updated At : {ticket.updated_at}</h3>
                    </Stack>
                    <Stack
                      direction="row"
                      gap={4}
                      sx={{
                        "& h3": {
                          margin: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <h3>Category : {ticket.category}</h3>
                      <h3>Question : {ticket.question}</h3>
                    </Stack>
                  </Stack>
                  <Box
                    className="chatSection"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: 600,
                      borderRadius: "5px",
                      paddingTop: "30px",
                      backgroundColor: "#f7f7f7",
                      border: "1px groove grey",
                      overflow: "scroll",
                      paddingBottom: "200px",
                    }}
                  >
                    <h3>Correspondence</h3>
                    <hr />
                    {Object.keys(ticket).length > 0 ? (
                      <Stack
                        className="correspondence"
                        direction="column"
                        gap={2}
                      >
                        {"files" in ticket && ticket.files.length > 0 ? (
                          <Box>
                            <Divider />
                            <h3>Images Submitted :</h3>
                            <Stack direction={"row"} gap={2}>
                              {ticket.files.map((f, i) => (
                                <>
                                  <Box
                                    onClick={() => {
                                      setImageId(f.id);
                                      setOpenImage(true);
                                    }}
                                    sx={{
                                      "& :hover": {
                                        cursor: "pointer",
                                        color: "lightblue",
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                                      alt=""
                                    />
                                  </Box>
                                </>
                              ))}
                            </Stack>
                          </Box>
                        ) : (
                          ""
                        )}
                        {ticket.messages?.map((m) => (
                          <Stack
                            direction={"column"}
                            gap={2}
                            className="message"
                            sx={{
                              width: "80%",
                              mx: "10px",
                              color:
                                m.sendedBy === "customercare" ? "white" : "",
                              backgroundColor:
                                m.sendedBy === "customercare"
                                  ? "#2979FF"
                                  : "#DCE9FF",
                              borderTopLeftRadius:
                                m.sendedBy === "customercare" ? "30px" : "",
                              borderTopRightRadius:
                                m.sendedBy === "customercare" ? "0px" : "30px",
                              borderBottomLeftRadius: "30px",
                              borderBottomRightRadius: "30px",
                              alignSelf:
                                m.sendedBy === "customercare" ? "end" : "start",
                              "& h3": {
                                padding: 0,
                                margin: 0,
                              },
                              "& h4": {
                                padding: 0,
                                margin: 0,
                              },
                            }}
                          >
                            <h4
                              style={{
                                color:
                                  m.sendedBy === "customercare" ? "white" : "",
                              }}
                            >
                              {m.time}
                            </h4>
                            <Divider flexItem />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: String(m.message).replaceAll(
                                  "\n",
                                  "<br>"
                                ),
                              }}
                            />
                            {"files" in m && m.files.length > 0 ? (
                              <Box>
                                <Divider />
                                <h3>Attachments :</h3>
                                <Stack direction={"row"} gap={2}>
                                  {m.files.map((f, i) => (
                                    <>
                                      <Box
                                        onClick={() => {
                                          setImageId(f.id);
                                          setOpenImage(true);
                                        }}
                                        sx={{
                                          "& :hover": {
                                            cursor: "pointer",
                                            color: "lightblue",
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                          src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                                          alt=""
                                        />
                                      </Box>
                                    </>
                                  ))}
                                </Stack>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Stack>
                        ))}
                        {/* <Button
                      sx={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: "20px",
                      }}
                      variant="contained"
                      onClick={handleClickOpen}
                      endIcon={<SendIcon />}
                    >
                      Reply
                    </Button> */}
                        {ticket.package_status !== "closed"
                          ? innerWidth > 730 && (
                              <Stack direction="row" gap={2}>
                                <Button
                                  color="error"
                                  variant="contained"
                                  onClick={handleCloseCase}
                                >
                                  Close Case
                                </Button>
                                <Button
                                  sx={{ marginRight: 0, marginLeft: "auto" }}
                                  variant="contained"
                                  onClick={handleClickOpen}
                                  endIcon={<SendIcon />}
                                >
                                  Reply
                                </Button>
                              </Stack>
                            )
                          : innerWidth > 730 && (
                              <Box sx={{ width: "100%", textAlign: "center" }}>
                                <h1>
                                  This Ticket Is Closed by{" "}
                                  {ticket.closed_by === "customer"
                                    ? "You"
                                    : "customer care"}
                                </h1>
                                {(ticket.closed_by === "customercare" ||
                                  ticket.closed_by === "Little Box") && (
                                  <div>
                                    <h2 style={{ fontWeight: 400 }}>
                                      If you are unsatisfied by our reply you
                                      can reopen this case
                                    </h2>
                                    <span>
                                      <Button
                                        size="large"
                                        variant="outlined"
                                        onClick={handleReopenCase}
                                      >
                                        <span style={{ fontWeight: 800 }}>
                                          Reopen
                                        </span>
                                      </Button>
                                    </span>
                                  </div>
                                )}
                              </Box>
                            )}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
                <Dialog
                  // fullScreen
                  open={open}
                  sx={{ width: "98%" }}
                  onClose={handleClose}
                >
                  <DialogTitle>Enter Message</DialogTitle>
                  <DialogContent>
                    <TextField
                      sx={{ border: "" }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      autoFocus
                      id="filled-textarea"
                      aria-expanded={true}
                      fullWidth={true}
                      multiline
                      rows={15}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        marginBottom: 0,
                        marginTop: "auto",
                      }}
                    >
                      {files.map((f) => (
                        <Stack
                          marginTop={1.2}
                          direction={"row"}
                          gap={1}
                          alignItems={"center"}
                          sx={{
                            padding: "2px 5px",
                            borderRadius: "10px",
                            bgcolor: "lightgrey",
                            width: "100%",
                          }}
                        >
                          <h4 style={{ padding: 0, margin: 0 }}>
                            {f.dataReq.name}{" "}
                          </h4>{" "}
                          <ClearIcon
                            onClick={() => handleDeleteFiles(f.id)}
                            sx={{
                              "&:hover": {
                                color: "red",
                                cursor: "pointer",
                                transform: "scale(1.3)",
                              },
                            }}
                          />
                        </Stack>
                      ))}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <label style={{ marginRight: "auto", marginLeft: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px",
                          // border: "1px solid lightblue",
                          alignItems: "center",
                          flexDirection: "row",
                          "& :hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <h2>Add Attachments</h2>
                        <AttachFileIcon />
                      </Box>
                      <Box sx={{ display: "none" }}>
                        <TextField
                          required
                          variant="outlined"
                          type="file"
                          id="file"
                          // label="Upload Images"
                          onChange={(e) => handleChange([...e.target.files])}
                          inputProps={{
                            multiple: true,
                            accept: "image/jpeg",
                          }}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                        />
                      </Box>
                    </label>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSend}>Send</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={openRetryBox} onClose={handleRetryClose}>
                  <DialogTitle>Erorr In Sending Response</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      There was an error in sending your response, please try
                      again
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleRetryClose}>Cancel</Button>
                    {retryLoading ? (
                      <CircularProgress
                        size={"2rem"}
                        sx={{ marginLeft: "10px" }}
                      />
                    ) : (
                      <Button onClick={handleRetry}>Retry</Button>
                    )}
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openCloseRetryBox}
                  onClose={handleCloseRetryClose}
                >
                  <DialogTitle>Couldn't close case</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      There was an error in closing your case, please try again
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseRetryClose}>Cancel</Button>
                    {retryLoading ? (
                      <CircularProgress
                        size={"2rem"}
                        sx={{ marginLeft: "10px" }}
                      />
                    ) : (
                      <Button onClick={handleCloseRetry}>Retry</Button>
                    )}
                  </DialogActions>
                </Dialog>
                <Modal
                  open={openImage}
                  onClose={handleImageClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ImageViewer handleClose={handleImageClose} id={imageId} />
                </Modal>
              </Container>
            </div>
          </Stack>
        </>
      )}
    </>
  );
};

export default ChatModule;
